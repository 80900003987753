import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import QrCode from "./QrCode";

const PinToFind = () => {
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let code = query.get("qr");

  return <QrCode codeToFind={JSON.stringify(code)} />;
};

export default PinToFind;
