import { useEffect, useState } from "react";
import "./App.css";
import Scanner from "./components/scanner";
import {link, useLocation, BrowserRouter, Route,Routes } from "react-router-dom"

import QrCode from "./components/QrCode";
import PinToFind from "./components/PinToFind";
// import GetDog from "./components/GetDog";
// import Basic_fetch from "./components/Fetch/1-basic-fetch";
// import Try_Async from "./components/Fetch/2-try-async";
//import UrlRequest from "./components/Fetch/3-url-request";



const Child = ({code}) =>{

<>
  return(

    {code
    ? <h3>the code is {code}</h3>
    : <h4>No code</h4>}  
  )

</>


}


// function useQuery(){


// return new URLSearchParams(useLocation().search)

// }




function App() {

  //let query = useQuery();

  return (

    <BrowserRouter>

    <div className="App">
      {/* <h3>QR code</h3>
      
      <br/> */}
     
     {/* <Basic_fetch/>
     <Try_Async /> */}
     {/* <UrlRequest/> */}


      {/* <QrCode code={query.get("code")}/> */}

     <Routes>

        <Route path="/"

        Component={Scanner}

       >

        </Route>

        <Route  path="/code"

         Component={PinToFind}>

        </Route>


      

     </Routes>
     
    </div>
    
    </BrowserRouter>
  );
}

export default App;
