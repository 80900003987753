import React from "react";
import { useEffect, useState } from "react";
import { Watch } from "react-loader-spinner";
import EntryReceived from "./EntryReceived";
import SuccessForm from "./SuccessForm";
import AlreadyRedeemed from "./AlreadyRedeemed";

const QrCode = ({codeToFind}) => {
  //let query = useQuery();

  // console.log("================================");
  // console.log(codeToFind);
  // console.log("--------------------------------");

  const [dataObject, setDataObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [haveFormData, setHaveFormData] = useState(false);
  const [codeUsed, setCodeUsed] = useState(false);

  async function getPindata(pinToFind) {


    //console.log(pinToFind);

    let splitter = pinToFind.split("-");

    setLoading(true);

    //console.log({ pinToFind });

    const str =
      //"https://localhost:44390/PinGen/CheckPin?pinToFind=" + pinToFind;
      "https://flexy.kiwijones.com/PinGen/CheckPin?pinToFind=" + pinToFind;

    try {

      console.log(str);

      const url = new URL(str);

      const request = new Request(url, {
        method: "GET",
        cache: "no-store",
        mode: "cors",
        credentials: "same-origin",
      });

      let response = await fetch(request);

      //console.log(response.status);

      if (!response.ok) throw new Error("Invalid request");



      let dataobj = await response.json();

      setDataObject(dataobj);

      console.log(dataobj);

      setLoading(false);

      if (dataobj.pinStatusId === 2) {
        console.log("true");
        setCodeUsed(true);  // code  has been redeemed 
      }
      
    } catch (err) {
      
      console.log("error");

      //error(err)
    }
  }

  useEffect(() => {
    // let code = query.get("qr")

    // console.log(code)

    // removing quotes from string
    getPindata(codeToFind.replace(/^"(.+)"$/,'$1'));

  }, []);

  return (
    <div>
      
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Watch color="green" radius={"15px"} width={80} />
        </div>
      ) : (
        <div></div>
      )}
      {/* Data has been returnrd from the API */}
      {dataObject ? (
        <>
          <div>
            Success: <a href={"http://" + dataObject.pin}>{dataObject.pin}</a>
          </div>

          <div>
            {(dataObject.pinActionId === 2) && (dataObject.pinStatusId === 1) ? (
              <div>

                {haveFormData ? (
                  <EntryReceived />
                ) : (
                  <>
                    <img
                      src="https://qr.kiwijones.com/images/winner.jpg"
                      alt="winner"
                    />

                    <br />

                    <SuccessForm
                      setHaveFormData={setHaveFormData}
                      obj={dataObject.pinId}
                    />
                  </>
                )}
              </div>
            ) : (
             
              codeUsed 
              ? ( <> <AlreadyRedeemed /> </>) 
              : (<div>
                <h2>Désolé, le code QR scanné n'est pas gagnant</h2>
                {/* <img
                  src="https://qr.kiwijones.com/images/loser.jpg"
                  alt="loser"
                />
                 */}
              </div>)
            )}
          </div>
        </>
      ) : (

        
        <div id="reader"></div>
      )}
    </div>
  );
};
export default QrCode;
