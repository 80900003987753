import { Html5Qrcode, Html5QrcodeScanner } from "html5-qrcode";
import { useEffect, useState } from "react";
import SuccessForm from "./SuccessForm";

import QrCode from "./QrCode";
import CodeNotExist from "./CodeNotExist";


// import Winner from '../images/winner.jpg'
// import Loser from '../images/loser.jpg'

function Scanner() {

  let codeToFind 
  const [scanResult, setScanResult] = useState(null);
  const [dataObject, setDataObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [codeNotExist,setCodeNotExist] = useState(false)

  useEffect(() => {

    setLoading(false);

    const scanner = new Html5QrcodeScanner("reader", {
      qrbox: {
        width: 250,
        height: 250,
      },

      fps: 5,
    });

    setDataObject(null);

    scanner.render(success, error);

    function refreshPage() {
      window.location.reload(false);
    }

    function success(result) {

      console.log("success");
      
      setCodeNotExist(false)
      
      try {
        if((result.includes("http")) && (result.includes("qr"))  ) {

          let urlSplit = result.split("=")

          codeToFind = urlSplit[1]
      }


      console.log(codeToFind)

      let splitter = codeToFind.split("-");

      console.log(splitter.length);

      
      if (splitter.length === 5) {
        scanner.clear();
        // if the qr code is ours
          setScanResult(codeToFind);

         //getPindata(result);

        // console.log(dataObject);
      } else {


        // if the QR code isn't ours then reload the page
        console.log('not ours')  
        setCodeNotExist(true)
        //refreshPage();
        setScanResult(false)
      
       
      }
      } catch (error) {

        //refreshPage();

        setCodeNotExist(true)
      }
     
    }

    function error(err) {
      console.log("error");

      console.log(err);
    }
  }, []); 

  return (

    <div>
        
        <div id="reader"></div>



        {scanResult 
        ?(<> <QrCode codeToFind={scanResult} /></>)
      :(<></>)
      }
       {codeNotExist
       ?(<><CodeNotExist /></>)
      :(<></>)
      }
    </div>
  );
}

export default Scanner;
