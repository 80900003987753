import React, { ReactNode, useState, Component } from "react";
// import { render } from "react-dom";
// import { TailSpin } from "react-loader-spinner";

import { TextField, Button, FormControl, FormLabel } from "@material-ui/core";

const SuccessForm = ({setHaveFormData, obj}) => {

  const [inputs, setInputs] = useState({
    name: "",
    phoneNo: "",
    pinId: obj,
  });


  const onClickHandler = (e) => {

    console.log("onClickHandler");
    
    setHaveFormData(true)

    e.preventDefault();
    //setLoading(true);

    let endpoint = "https://flexy.kiwijones.com/PinGen/redeem";

    if (window.confirm("Submit form")) {


      console.log(inputs);

      let jsonString = JSON.stringify(inputs);

      let request = new Request(endpoint, {
        method: "POST",
        body: jsonString,
        headers: {
          "content-type": "application/json",
        },
      });

      fetch(request)
        .then((response) => {
          if (!response.ok) throw new Error("Invalid");
          return response.text();
        })
        .then((txt) => {
          //setLoading(false);
          console.log(txt);

          setHaveFormData(true)

        })
        .catch(console.warn);
    }
  };

  
  const handlechange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // console.log("form");

  // console.log(obj);
  // console.log("-----------------------------");

  //let frr = JSON.stringify(obj);

  return (
    <>
      {/* {loading ? (
        <TailSpin color="green" radius={"40px"} secondaryColor="blue" />
      ) : (
        <div></div>
      )} */}
      <FormControl>
        <FormLabel>Fill in form to redeem prize</FormLabel>

        <TextField
          name="pinId"
          //placeholder={obj}
          color="primary"
          disabled
          onChange={handlechange}
        ></TextField>

        <div>
          <FormLabel>Enter name</FormLabel>
        </div>
        <div>
          <TextField
            name="name"
            value={inputs.name}
            //placeholder="Name"
            color="primary"
            required
            onChange={handlechange}
          ></TextField>
        </div>

        <FormLabel>Enter Phone No</FormLabel>
        <TextField
          inputProps={{ type: "number" }}
          name="phoneNo"
          value={inputs.phoneNo}
          //placeholder="PhoneNo"
          required
          onChange={handlechange}
        ></TextField>

        <Button variant="outlined" color="secondary" onClick={onClickHandler}>
          Submit
        </Button>
      </FormControl>
    </>
  );
};

export default SuccessForm;
